









































































































































































































































































































































import { IProductionSiteDetails } from "@/lib/types/energy-assets/production_site";
import {
  computed,
  defineComponent,
  provide,
  reactive,
  toRefs,
  watch
} from "@vue/composition-api";
import {
  deleteProductionSiteData,
  getProductionSiteData
} from "@/api/energy-assets/production_site";
import { getProductionDataList } from "../../../../api/energy-data/production_data";
import { IProductionData } from "@/lib/types/energy-data/production_data";
import { dynamicSort, seperateThousand } from "@/utils";
import { formattedAddress } from "@/helpers/formats";
import { rootInstance } from "@/helpers/composition";
import { OrganizationTypes } from "@/lib/types/base";
import { cloneDeep } from "lodash";
import { Notification } from "element-ui";
import DateTimePicker from "@/components/DateTimePicker.vue";
import GetFromEpias from "@/components/forms/GetFromEpias.vue";
import AnimatedNumber from "animated-number-vue";

export default defineComponent({
  name: "ProductionSite",
  components: {
    AggregatedChart: () => import("@/components/charts/AggregatedChart.vue"),
    ApexChart: () => import("@/components/charts/ApexChart.vue"),
    AggregatedDatalist: () =>
      import("@/components/datalists/AggregatedDatalist.vue"),
    PieChart: () => import("@/components/charts/PieChart.vue"),
    ProductionSiteCrudForm: () =>
      import("@/components/forms/ProductionSiteCrudForm.vue"),
    ProductionMatchingList: () =>
      import("@/components/production-site/ProductionMatchingList.vue"),
    ProductionSiteDetails: () =>
      import("@/components/production-site/ProductionSiteDetails.vue"),
    FormBase: () => import("@/components/FormBase.vue"),
    FormItem: () => import("@/components/FormItem.vue"),
    ProductionDataUploadForm: () =>
      import("@/components/forms/ProductionDataUploadForm.vue"),
    DateTimePicker,
    GetFromEpias,
    AnimatedNumber,
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if (
        [OrganizationTypes.RETAILER, OrganizationTypes.PRODUCER].includes(
          vm.$store.state.user.details.organization_type
        )
      ) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    });
  },
  setup() {
    const { root } = rootInstance();

    const roles = computed(() => root.$store.getters["user/roles"]);

    const state = reactive({
      details: { producer: {} } as IProductionSiteDetails,
      detailsInit: {} as IProductionSiteDetails,
      detailsLoaded: false,
      productionData: {} as Partial<IProductionData>,
      productionDataLoaded: false,
      editModalVisible: false,
      datalistVisible: false,
      activeTab: "overview",
      uploadModalVisible: false,
      isRouterAlive: true,
      currentTab: "epias",
      productionSiteFormValue: false,
      chartName: "Stacked Area Chart",
    });

    const epiasForm = reactive({
      power_plant_id: computed(() => state.details.power_plant_id),
      start_date: "",
      end_date: "",
    });

    const productionSiteDataFn = () => {
      state.detailsLoaded = false;
      state.productionDataLoaded = false;

      getProductionSiteData(root.$route.params.id)
        .then(async res => {
          state.details = res;
          state.detailsInit = Object.freeze(state.details);
          state.detailsLoaded = true;

          await getProductionDataList({
            productionSite: res.id,
            productionStartAfter: root.$store.state.period[0],
            productionStartBefore: root.$store.state.period[1],
            limit: 24,
            offset: 0,
          })
            .then(res => {
              state.productionData = res;
              state.productionDataLoaded = true;

              if (state.productionData.results) {
                state.productionData.results.sort(
                  dynamicSort("production_start")
                );
              }
            })
            .catch(() => {
              state.productionDataLoaded = false;
              // alert(JSON.stringify(err.body));
              console.error("ProductionSite.vue productionSiteDataFn");
            });
        })
        .catch(err => {
          state.detailsLoaded = false;
          if (err.statusCode === 404) root.$router.push({ name: "NotFound" });
        });
    };

    productionSiteDataFn();

    const closeEditModal = () => {
      state.editModalVisible = false;
      state.details = cloneDeep(state.detailsInit);
    };

    const deleteSite = () => {
      root.$confirm(
        /* Message */
        `
          Are you sure you want to delete <code class="bg-border text-dark-l2 rounded-sm px-1">${state.details.name}</code>?
          <br>
          This action can only be <span class="font-medium">reversed by a superadmin</span>.
        `,
        /* Title */
        root.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: root.$i18n.t("labels.delete") as string,
          cancelButtonText: root.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;

              await deleteProductionSiteData(root.$route.params.id)
                .then(() => {
                  instance.confirmButtonLoading = false;

                  Notification({
                    title: "Operation successful",
                    message: `${state.details.name} deleted successfully`,
                    type: "success",
                    position: "bottom-left",
                  });

                  root.$router.push({
                    name: "Producer",
                    params: {
                      id:
                        typeof state.details.producer !== "string"
                          ? state.details.producer.id
                          : "",
                    },
                  });

                  done();
                })
                .catch(err => {
                  // if (err) alert(JSON.stringify(err));
                  if (err) console.error("ProductionSite.vue beforeClose");
                  else {
                    alert(
                      "Request Timeout! Please refresh the page and check back again."
                    );
                  }
                });
              /*  */
            } else {
              done();
            }
          },
        }
      );
    };

    const siteUpdated = () => {
      productionSiteDataFn();
      state.editModalVisible = false;
    };

    const productionImported = () => {
      state.isRouterAlive = false;
      state.uploadModalVisible = false;
      root.$nextTick(() => {
        state.isRouterAlive = true;
      });
    };

    /**
     * ! Getters
     */
    const totalAmountData = computed(() => {
      return {
        total_amount_produced: state.productionData.total_amount_produced,
        total_amount_matched: state.productionData.total_amount_matched,
        total_consumption_sites_matched:
          state.productionData.total_consumption_sites_matched,
      };
    });

    watch(
      () => root.$store.state.period,
      (newVal, oldVal) => {
        root.$router
          .replace({
            query: Object.assign(
              {},
              {
                start: root.$store.state.period[0].split("T")[0],
                end: root.$store.state.period[1].split("T")[0],
              }
            ),
          })
          .catch(() => {});

        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          productionSiteDataFn();
        }
      },
      { deep: true, immediate: true }
    );

    const hourlyAggregatedData = computed(() =>
      state.productionData.hourly_aggregated_data?.sort(dynamicSort("hour"))
    );
    const allHourlyData = computed(() =>
      state.productionData.hourly_aggregated_data_for_each_day?.sort(
        dynamicSort("date")
      )
    );

    provide(
      "productionData",
      computed(() => state.productionData)
    );

    const changeF = (newVal: any) => {
      state.productionSiteFormValue = newVal;
    };
    const closeDialog = () => {
      if (state.productionSiteFormValue) {
        root.$confirm(
          /* Message */
          root.$i18n.t("messages.sure_to_close") as string,
          /* Title */
          root.$i18n.t("labels.warning") as string,
          {
            confirmButtonText: "OK",
            cancelButtonText: root.$i18n.t("labels.cancel") as string,
            type: "warning",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,

            beforeClose: async (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                state.isRouterAlive = false;
                root.$nextTick(() => {
                  state.isRouterAlive = true;
                });
                state.currentTab = "epias";
                state.uploadModalVisible = false;
                state.editModalVisible = false;
                instance.confirmButtonLoading = false;
                done();
                /*  */
              } else {
                done();
              }
            },
          }
        );
      } else {
        state.currentTab = "epias";
        state.isRouterAlive = false;
        root.$nextTick(() => {
          state.isRouterAlive = true;
        });
        state.editModalVisible = false;
        state.uploadModalVisible = false;
        state.productionSiteFormValue = false;
      }
    };
    const chartChange = (e: any) => {
      state.chartName = e;
    };
    return {
      ...toRefs(state),
      epiasForm,
      roles,
      OrganizationTypes,
      totalAmountData,
      hourlyAggregatedData,
      allHourlyData,
      formattedAddress,
      closeEditModal,
      siteUpdated,
      productionImported,
      deleteSite,
      seperateThousand,
      closeDialog,
      changeF,
      chartChange,
    };
  },
});
